/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { theme } from './GlobalStyles';
import { ReactComponent as Mobile } from '../../images/svgs/mobile.svg';
import { ReactComponent as Arrow } from '../../images/svgs/arrow-nav.svg';
import { information } from '../../information';
import { navMenu } from './navMenu';
import { ButtonPrimary } from './StyledComponents';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
    active: boolean;
    scroll?: boolean;
}

const MobileNav = styled.button<Props>`
    height: 80px;
    box-shadow: ${({ active }) => (active ? 'none' : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px')};
    display: flex;
    transition: box-shadow 0.2s ease-in;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    background: white;
    z-index: 10;
    width: 100vw;
    padding: 16px 16px;
    border: none;
    .logo {
        width: 135px;
    }
    .burger-phone-container {
        display: flex;
        align-items: center;
        width: 88px;
        justify-content: space-between;
        a {
            display: flex;
            svg {
                fill: ${theme.colors.orange3};
                width: 20px;
            }
        }
    }
    .line {
        border-radius: 15px;
    }

    .line-one {
        margin-bottom: 4px;
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        top: ${({ active }) => (!active ? '0' : '5px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(45deg)')};
        width: ${({ active }) => (!active ? '24px' : '24px')};
    }

    .line-two {
        margin-bottom: 4px;
        width: ${({ active }) => (!active ? '18px' : '0px')};
        transition: 0.5s cubic-bezier(0.1, 0.7, 0.6, 0.9);
    }
    .line-three {
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        bottom: ${({ active }) => (!active ? '0' : '9px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(-45deg)')};
        width: ${({ active }) => (!active ? '24px' : '24px')};
    }
    @media ${theme.device.laptop} {
        display: none;
        height: 140px;
        margin: 15px 32px;
        .burger-phone-container {
            display: none;
        }
    }
`;

const MobileMenu = styled.div<Props>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 3;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 160px 100px;
    transition: transform 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
    transform: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};
    ul {
        padding: 0;
        list-style: none;
        svg {
            height: 8px;
            margin-left: 8px;
            fill: ${theme.colors.orange3};
        }
        button {
            font-size: 18px;
            font-weight: bold;
            color: ${theme.colors.orange3};
        }
    }
    li,
    a {
        margin: 16px 0px;
        font-weight: 700;
    }
    a {
        font-size: 18px;
        color: ${theme.colors.orange3};
        text-decoration: none;
    }
    button {
        background: none;
        border: none;
    }
    .back-button {
        position: absolute;
        align-items: center;
        display: flex;
        justify-content: start;
        left: 80px;
        top: 120px;
        text-decoration: underline;
        font-size: 18px;
        font-weight: bold;
        color: ${theme.colors.orange3};
        p {
            color: ${theme.colors.orange3};
        }
        a {
            color: ${theme.colors.orange3};
        }
        svg {
            margin-right: 8px;
            fill: ${theme.colors.orange3};
            transform: rotate(90deg);
        }
    }
    @media ${theme.device.laptop} {
        display: none;
    }
`;

const StyledNav = styled.nav``;

const Burger = styled.div<Props>`
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: end;
    border: none;
    background: none;
    margin-left: 32px;
    div {
        border-radius: 15px;
        height: 3px;
        background: ${theme.colors.orange3};
    }
    @media ${theme.device.laptop} {
        display: none;
    }
`;

interface NewProps {
    active: boolean;
    scroll: boolean;
}

const DesktopNav = styled.div<NewProps>`
    display: none;
    @media ${theme.device.laptop} {
        display: flex;
        transition: box-shadow 0.2s ease-in;
        transition: background-color 0.2s ease-in;
        box-shadow: ${({ scroll }) =>
            !scroll ? 'none' : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'};
        background: white;
        align-items: center;
        position: fixed;
        top: 0;
        width: 100vw;
        justify-content: space-around;
        z-index: 4;
        margin: 0px 0px 0px;
        padding: 12px 0px 14px;
        .logo {
            width: 205px;
            margin-right: 00px;
        }
        .main-menu {
            display: flex;
            width: 420px;
            position: relative;
            right: 00px;
            justify-content: space-between;
            list-style: none;

            > li {
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: 0.2s ease-in;
                a {
                    text-decoration: none;
                    color: ${theme.colors.gray5};
                }
                svg {
                    height: 8px;
                    fill: ${theme.colors.gray5};
                    margin-left: 10px;
                    transition: 0.2s ease-in;
                }
                &:hover {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            > li {
                display: inline-block;
                position: relative;
            }
            > li:hover:after {
                transform: scaleX(1);
                transform-origin: left right;
            }
            > li:after {
                content: '';
                position: absolute;
                width: 50%;
                transform: scaleX(0);
                height: 2px;
                bottom: -3px;
                left: 24.9%;
                background-color: ${theme.colors.orange3};
                transform-origin: left right;
                transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
            }
        }

        .desktop-buttons {
            display: flex;

            button {
                margin-right: 20px;
            }
            .desktop-mobile {
                background: ${theme.colors.orange3};
                width: 60px;
                flex-shrink: 0;
                height: 56px;

                display: flex;
                justify-content: center;
                height: auto;
                border-radius: 100%;
                border: 2px solid ${theme.colors.orange3};
                svg {
                    width: 20px;
                    stroke: ${theme.colors.gray1};
                    fill: ${theme.colors.gray1};
                    transition: 0.2s ease-in;
                }
                &:hover {
                    svg {
                        transform: rotate(10deg);
                    }
                }
            }

            .desktop-mobile:hover {
                background: none;
                transition: 0.2s ease-in;
                svg {
                    transition: 0.2s ease-in;
                    stroke: ${theme.colors.orange3};
                    fill: ${theme.colors.orange3};
                }
            }
        }
    }
    @media ${theme.device.laptopL} {
        .logo {
            width: 225px;
        }
        .main-menu {
            width: 508px;
        }
        .desktop-buttons {
            display: flex;
            button {
                margin-right: 20px;
            }
        }
    }
`;

const DesktopMenu = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    max-width: 283px;
    min-width: 283px;
    opacity: 1;
    z-index: 2;
    box-shadow: 0px 51px 125px rgba(109, 106, 93, 0.09),
        0px 18.5323px 39.5627px rgba(109, 106, 93, 0.0393285),
        0px 8.27833px 15.9688px rgba(109, 106, 93, 0.0425114),
        0px 4.0063px 6.7726px rgba(109, 106, 93, 0.0304631);
    border-radius: 15px;
    list-style: none;
    left: -16px;
    top: 50px;
    overflow: hidden;
    a {
        padding: 0px 70px 0 0;
        color: ${theme.colors.gray5};
        text-decoration: none;
    }

    li {
        transition: 0.2s ease-in;

        z-index: 0;
        padding: 8px 16px;

        &:hover {
            background: ${theme.colors.orange2};
        }
    }

    &:hover {
        opacity: 1;
    }
`;

const DesktopMenuWide = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: row;
    background: white;
    overflow: hidden;
    opacity: 1;
    z-index: 2;
    box-shadow: 0px 51px 125px rgba(109, 106, 93, 0.09),
        0px 18.5323px 39.5627px rgba(109, 106, 93, 0.0393285),
        0px 8.27833px 15.9688px rgba(109, 106, 93, 0.0425114),
        0px 4.0063px 6.7726px rgba(109, 106, 93, 0.0304631);
    border-radius: 15px;
    list-style: none;
    left: -315px;
    top: 50px;
    a {
        padding: 0px 70px 0 0;
        color: ${theme.colors.gray5};
        text-decoration: none;
    }

    li {
        min-width: 300px;
        transition: 0.2s ease-in;
        z-index: 0;
        padding: 8px 16px;

        &:hover {
            background: ${theme.colors.orange2};
        }
    }

    &:hover {
        opacity: 1;
    }
    .special-menu {
        position: relative;
        bottom: 40px;
    }
`;

const SubMenu = styled.ul`
    li {
        list-style: none;
        span {
            position: relative;
            left: 16px;
        }
    }
`;

type MenuType = {
    name: string;
    path: string;
    url?: string;
};

const debounce = (func: { (): void; apply?: any }, ms: number) => {
    let timer: any;
    return (...args: any) => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = undefined;
                func.apply(this, args);
            }, ms);
        }
    };
};

export const Nav = () => {
    const [active, setActive] = useState(false);
    const [menuName, setMenuName] = useState<string | null>(null);
    const [menuState, setMenuState] = useState<MenuType[] | null>(null);
    const isSSR = typeof window === 'undefined';
    const [scroll, setScroll] = useState(isSSR ? false : window.scrollY > 180);
    const navRef = useRef(null);

    const onScroll = useCallback(
        debounce(() => setScroll(window.scrollY > 180), 50),
        [scroll]
    );

    const handleNavClick = (e: { target: React.MutableRefObject<null> } | any) => {
        e.target !== navRef.current ? setActive(false) : setActive(true);
    };

    // useEffect(() => {
    //     // document.addEventListener('mouseover', handleNavClick);
    //     // setScroll(true);
    //     // return () => {
    //     //     document.addEventListener('mouseover', handleNavClick);
    //     // };
    // }, []);

    useEffect(() => {
        if (isSSR) return;
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <StyledNav onMouseLeave={handleNavClick} ref={navRef}>
            <MobileNav scroll={scroll} active={active} className="">
                <Link aria-label="Just For Kids Logo" to="/">
                    <StaticImage
                        alt="logo"
                        src="../../images/common/logo.png"
                        placeholder="none"
                        quality={100}
                        className="logo"
                    />
                </Link>
                <div className="burger-phone-container">
                    <a aria-label="call Dental Renaissance" href={`tel:${information.phone}`}>
                        <Mobile>
                            <title>Mobile Phone</title>
                        </Mobile>
                    </a>
                    <Burger
                        onClick={() => {
                            setActive(active => !active);
                        }}
                        active={active}
                    >
                        <div className="line-one line"></div>
                        <div className="line-two line"></div>
                        <div className="line-three line"></div>
                    </Burger>
                </div>
            </MobileNav>
            <MobileMenu active={active}>
                <ul>
                    {!menuState &&
                        navMenu.map(({ menu, name }) => {
                            if (menu) {
                                return (
                                    <li key={name}>
                                        <button
                                            style={{ color: `${theme.colors.orange3}` }}
                                            onClick={() => {
                                                setMenuName(name);
                                                setMenuState(menu);
                                            }}
                                        >
                                            {name}
                                            <Arrow />
                                        </button>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={name}>
                                        <button
                                            style={{ color: `${theme.colors.orange3}` }}
                                            onClick={() => {
                                                setMenuName(name);
                                                setMenuState(menu);
                                            }}
                                        >
                                            {name}
                                        </button>
                                    </li>
                                );
                            }
                        })}
                </ul>
                {
                    <ul>
                        {menuState && (
                            <button
                                onClick={() => {
                                    setMenuState(null);
                                    setMenuName('');
                                }}
                                className="back-button"
                            >
                                <Arrow />
                                <p>{menuName}</p>
                            </button>
                        )}
                        {menuState &&
                            menuName &&
                            menuState.map(subItems => {
                                return (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        onClick={() => {
                                            setActive(false);
                                        }}
                                        key={subItems.name}
                                    >
                                        <Link to={subItems.path}>{subItems.name}</Link>
                                    </li>
                                );
                            })}
                    </ul>
                }
            </MobileMenu>
            <DesktopNav scroll={scroll} className="" active={active}>
                <Link aria-label="Just For Kids Logo" to="/">
                    <StaticImage
                        alt="logo"
                        src="../../images/common/logo.png"
                        placeholder="none"
                        quality={100}
                        className="logo"
                    />
                </Link>
                <ul className="main-menu">
                    {navMenu.map(({ menu, name, path }) => {
                        if (menu && name !== 'Procedures') {
                            return (
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                        setMenuName(name);
                                        setMenuState(menu);
                                    }}
                                    key={name}
                                >
                                    <Link onClick={() => setActive(false)} to={path}>
                                        {name}
                                        <Arrow />
                                    </Link>
                                    <DesktopMenu>
                                        {active &&
                                            menuName === name &&
                                            menuState &&
                                            menuState.map(
                                                (subItems: {
                                                    name: string;
                                                    path: string;
                                                    externalPath?: string;
                                                }) => {
                                                    return (
                                                        <li key={subItems.name}>
                                                            {subItems.path ? (
                                                                <Link
                                                                    style={{
                                                                        color: `${theme.colors.gray5}`,
                                                                    }}
                                                                    onClick={() => setActive(false)}
                                                                    to={subItems.path}
                                                                >
                                                                    {subItems.name}
                                                                </Link>
                                                            ) : (
                                                                <a
                                                                    href={subItems.externalPath}
                                                                    style={{
                                                                        color: `${theme.colors.gray5}`,
                                                                    }}
                                                                    rel="noopener noreferrer"
                                                                    target="__blank"
                                                                >
                                                                    {subItems.name}
                                                                </a>
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </DesktopMenu>
                                </li>
                            );
                        } else if (name === 'Procedures') {
                            return (
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                        setMenuName(name);
                                        setMenuState(menu);
                                    }}
                                    key={name}
                                >
                                    <Link onClick={() => setActive(false)} to={path}>
                                        {name}
                                        <Arrow />
                                    </Link>
                                    <DesktopMenuWide>
                                        {active &&
                                            menuName === name &&
                                            menuState &&
                                            menuState.map(
                                                (subItems: {
                                                    [x: string]: any;
                                                    name: string;
                                                    path: string;
                                                    externalPath?: string;
                                                }) => {
                                                    return (
                                                        <div
                                                            className={`${
                                                                !subItems.name && 'special-menu'
                                                            }`}
                                                            key={subItems.name}
                                                        >
                                                            <li>
                                                                {subItems.path ? (
                                                                    <Link
                                                                        style={{
                                                                            color: `${theme.colors.gray5}`,
                                                                        }}
                                                                        onClick={() =>
                                                                            setActive(false)
                                                                        }
                                                                        to={subItems.path}
                                                                    >
                                                                        {subItems.name}
                                                                    </Link>
                                                                ) : (
                                                                    <a
                                                                        href={subItems.externalPath}
                                                                        style={{
                                                                            color: `${theme.colors.gray5}`,
                                                                        }}
                                                                        rel="noopener noreferrer"
                                                                        target="__blank"
                                                                    >
                                                                        {subItems.name}
                                                                    </a>
                                                                )}
                                                            </li>
                                                            {
                                                                <SubMenu>
                                                                    {subItems.subMenu.map(
                                                                        (item: {
                                                                            name: string;
                                                                            path: string;
                                                                        }) => {
                                                                            return (
                                                                                <li key={item.name}>
                                                                                    <Link
                                                                                        style={{
                                                                                            color: `${theme.colors.gray5}`,
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            setActive(
                                                                                                false
                                                                                            )
                                                                                        }
                                                                                        to={
                                                                                            item.path
                                                                                        }
                                                                                    >
                                                                                        <span>
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </span>
                                                                                    </Link>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </SubMenu>
                                                            }
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </DesktopMenuWide>
                                </li>
                            );
                        } else {
                            return (
                                <Link key={name} to={path}>
                                    {name}
                                </Link>
                            );
                        }
                    })}
                </ul>
                <div className="desktop-buttons">
                    <Link to="#contact">
                        <ButtonPrimary color="green">
                            <span>Request an Appointment</span>
                        </ButtonPrimary>
                    </Link>
                    <a
                        aria-label="call Dental Renaissance"
                        href={`tel:${information.phone}`}
                        className="desktop-mobile"
                    >
                        <Mobile />
                    </a>
                </div>
            </DesktopNav>
        </StyledNav>
    );
};
